import React from "react"
import Helmet from "react-helmet"

export default () => (
  <div>
    <Helmet>
      <title>Not Found | Algonomicon</title>
    </Helmet>
  </div>
)
